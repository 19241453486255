
const $ = require('jquery');
global.$ = global.jQuery = $;

require('superfish');
require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox/dist/jquery.fancybox');
require('flexslider');


require('../css/app.css');
require('../js/main.js');