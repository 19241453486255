const $ = require('jquery');
global.$ = global.jQuery = $;

require('@fancyapps/fancybox/dist/jquery.fancybox.css');
require('@fancyapps/fancybox/dist/jquery.fancybox');
require('flexslider');
require('jwplayer');

$(document).ready(function() {
    $(function() {
        $('body').addClass('ready');
    });

    //$('ul.sf-menu').superfish();

    $(function() {
        //caches a jQuery object containing the header element
        var header = $("body");
        num = $('#familyis_body').offset().top;
        $(window).scroll(function() {
            var scroll = $(window).scrollTop();

            if (scroll >= num) {
                header.addClass("fixed");
            } else {
                header.removeClass("fixed");
            }
        });
    });

    // hide #back-top first
    $("#back-top").hide();

    // fade in #back-top
    $(function () {
        $(window).scroll(function () {
            if ($(this).scrollTop() > 100) {
                $('#back-top').fadeIn();
            } else {
                $('#back-top').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('#back-top a').click(function () {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });
});

var fancyOpts = {
    'transitionIn': 'elastic',
    'transitionOut': 'elastic',
    'padding'  : 0,
    'wrapCSS'  : 'b-custom-popup',
    'speedIn': 500,
    'speedOut': 300,
    'fitToView'  : false,
    'aspectRatio': true,
    'autoScale'     : false,
    'maxWidth': "100%",
    'maxHeight': "100%",
    'minHeight': "0",
    'autoSize'  : true,
    'closeBtn'  : true,
    'scrolling'  : 'no'
}
